.guide-container {
    min-height: calc(100vh - 85px);
    text-align: left;
    padding: 40px;
}

.guide-image {
    width: 50%;
    margin: 8px auto 2px auto;
}


@media only screen and (max-width: 600px) {
    .guide-container {
        padding: 10px;
    }

    .guide-image {
        width: 90%;
    }
}