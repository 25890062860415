html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;
    margin: 0 !important;
    /* user-select: none; */
}

body {
    background: url(../images/background/illo4_focus.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow-y: hidden;
}

tbody tr:first-child td {
    padding: 2px 20px 4px 5px !important;
}

thead tr:first-child th {
    padding: 8px 10px 2px 10px !important;
}