.app-container {
    background: #EEE;
    text-align: center;
    display: flex;
    flex-flow: column;
    height: 100%;
    margin: 0 12%;
}

.app-content {
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .app-container {
        margin: 0;
    }

    .app-content {
        height: auto;
    }
}