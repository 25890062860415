.spell-name {
    color: #A70000;
    font-weight: bold !important;
}

.spell-description {
    font-size: 15px;
    text-align: justify;
}

.spell-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.spell-higher-title {
    margin-bottom: 0px;
    color: #A70000;
}

.spell-higher-text {
    margin-top: 1px;
    text-align: justify;
    font-size: 15px;
}

.spell-source {
    font-size: 12px;
    font-style: italic;
    color: gray;
}

@media only screen and (max-width: 600px) {
    .spell-higher-title {
        text-align: center;
    }

    .spell-source {
        text-align: center;
    }
}