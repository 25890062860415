.header-container {
    background-color: #222 !important;
    color: #9d9d9d !important;
    font-size: 20px !important;
    /* padding: 5px !important; */
    height: 40px !important;
}

.header-title-container {
    margin: 0 0 0 20px;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .header-title-container {
        margin: 0;
    }
}