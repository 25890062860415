.list-item-container {
    padding: 20px;
    margin: 10px 0px;
}

.list-item-title {
    color: #A70000 !important;
    font-size: 24px !important;
}

.list-item-subheader {
    font-size: 18px !important;
}

.list-item-description {
    text-align: left !important;
    padding-top: 10px !important;
}

.list-item-higher-title {
    color: #A70000 !important;
    font-size: 16px !important;
    padding-top: 5px !important;
}

.list-item-higher-content {
    text-align: left;
}