.interface-container {
    height: calc(100% - 150px);
    display: flex;
}

.interface-left {
    height: 100%;
    flex: 0 1 50%;
}

.interface-right {
    flex: 0 1 50%;
    padding: 0px 5px;
    max-height: 100%;
    overflow-y: auto;
}

@media only screen and (max-width: 600px) {
    .interface-container {
        flex-direction: column-reverse;
    }

    .interface-left {
        height: calc(100vh - 120px);
    }

    .interface-right {
        overflow-y: auto;
        padding: 5px;
    }
}