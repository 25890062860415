.spell-card {
    width: 300px;
}

.spell-card-wrapper {
    border: 1px solid gray;
    border-radius: 4px;
}

@media only screen and (max-width: 600px) {
    .spell-card {
        min-width: 170px;
    }
}