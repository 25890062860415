.export-spellbook-container {
    margin: 25px auto 30px auto;    
    width: 21cm;
}

.export-spell-card {
    border: 2px solid #444444;
    display: flex;
    flex-direction: column;
}

.export-card-avatar {
    background-color: #A70000 !important;
}

.export-card-page-break {
    /* display: none; */
    width: 100%;
}


/* --- CARD --- */
.export-card-header {
    padding: 6px !important;
}
.export-card-content {
    padding: 0 5px 5px 5px !important;
    text-align: left !important;
    font-size: 10px !important;
}
.export-card-actions {
    padding: 0px 5px 5px 5px !important;
}

.export-card-title {
    color: #A70000 !important;
    font-weight: bold !important;
}

.export-card-subheader {
    font-size: 12px !important;
}

.export-card-description {
    text-align: left !important; 
    font-size: 10px !important;
    padding-top: 2px !important;
    white-space: pre-line;
}

.export-card-higher-title {
    color: #A70000 !important;
    font-size: 11px !important;
}

.export-card-higher-text {
    font-size: 10px !important;
    text-align: left;
}


@media only screen and (max-width: 600px) {
    .export-spellbook-container {
        width: auto;
    }
}