.spellbook-container {
    width: 100%;
    margin: 10px auto;
}

.spells-paper {
    width: 100%;
    min-height: 100px;
}

.button-container {
    margin-top: 10px;
    padding: 10px;
}

.export-button {
    margin: 20px !important;
}