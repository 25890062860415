:root {
    --carousel-width: 100%;
    --carousel-height: calc(0.7 * var(--carousel-width));
    --carousel-padding: 6px;
}

.viewport {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-frame {
    height: 97%;
    width: 100%;
}

.carousel {
    width: var(--carousel-width);
    height: 100%;
}

.scroll {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

ul.scroll {
    margin: 0;
    padding: 10px 0;
    list-style: none;
}

.scroll-item-outer {
    min-width: 360px;
    max-width: 380px;
    height: 100%;
    margin: 0 10px;
}

.scroll-item {
    width: var(--carousel-width);
    height: 100%;
}

.scroll-content {
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 600px) {
    :root {
        --carousel-width: 94vw;
    }

    .viewport {
        padding: 10px 0;
    }

    .carousel-frame {
        height: 99%;
        width: auto;
    }

    ul.scroll {
        padding: 0;
    }

    .scroll-item-outer {
        width: 100%;
        min-width: 100%;
    }
}



@supports (scroll-snap-align: start) {
    /* modern scroll snap points */
    .scroll {
        scroll-snap-type: x mandatory;
    }

    .scroll-item-outer {
        scroll-snap-align: center;
    }
}

@supports not (scroll-snap-align: start) {
    /* old scroll snap points spec */
    .scroll {
        -webkit-scroll-snap-type: mandatory;
        scroll-snap-type: mandatory;
        -webkit-scroll-snap-destination: 0 50%;
        scroll-snap-destination: 0 50%;
        -webkit-scroll-snap-points-x: repeat(100%);
        scroll-snap-points-x: repeat(100%);
    }

    .scroll-item-outer {
        scroll-snap-coordinate: 0 0;
    }
}