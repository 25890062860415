.card-header {
    padding: 2px 8px !important;
    height: 54px !important;
}

.card-content {
    padding: 2px 8px !important;
    height: calc(100% - 114px);
    overflow-y: auto;
    text-align: left;
}
.card-content p {
    font-size: 15px;
}

.card-actions {
    padding: 8px !important;
    height: 36px !important;
}