.about-container {
    min-height: calc(100vh - 85px);
    text-align: left;
    padding: 40px 80px;
}

.about-title {
    text-align: center;
}

.about-abstract {
    text-align: center;
}

.about-content {
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .about-container {
        padding: 10px;
    }
}